.generic-modal__image {
  width: 100%;
}

.generic-modal__title {
  margin-bottom: 16px;
}

.generic-modal__button-negative-vertical {
  margin-top: 8px;
}

.generic-modal__button-negative-horizontal {
  margin-right: 12px;
}

.generic-modal__footer--horizontal-alignment {
  display: flex;
  flex-flow: row-reverse;
}
