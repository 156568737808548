.desktop-redirect-qr,
.desktop-redirect-qr__page-header {
  padding-left: 18%;
  padding-right: 18%;
}

.desktop-redirect-qr__card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 27px 46px;
  border-radius: 16px;
  gap: 32px;
  align-items: center;
}

.desktop-redirect-qr__left-section {
  display: flex;
  flex-direction: column;
}

.desktop-redirect-qr__image,
.desktop-redirect-qr__image-qr {
  height: auto;
  width: 100%;
}

.desktop-redirect-qr__qr-container {
  display: flex;
  gap: 20px;
}

.desktop-redirect-qr__image-qr {
  max-width: 128px;
}

.desktop-redirect-qr__subtitle {
  margin-top: 16px;
  margin-bottom: 60px;
}

.desktop-redirect-qr__instruction {
  max-width: 160px;
}

.desktop-redirect-qr__container {
  overflow: unset;
  padding-left: 0;
  padding-right: 0;
}

.desktop-redirect-qr__page-header {
  border-bottom: 1px solid #eaecf0;
  width: 100%;
  height: var(--page-header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  background-color: #f9f9f9;
}
