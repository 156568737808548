.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader__message {
  margin-top: 24px;
}
