.post-login {
  position: relative;
  cursor: pointer;
}

.post-login__container {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(30, 44, 106, 0.08);
  border-radius: 6px;
  padding: 18px 16px;
  right: 0;
  min-width: 234px;
  display: flex;
  flex-direction: column;
  cursor: auto;
}

.post-login__list {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 38px;
  cursor: pointer;
}

.post-login__list:not(:last-child) {
  width: max-content;
  margin-bottom: 12px;
}

.post-login__list__translation {
  display: flex;
  align-items: center;
  gap: 8px;
}
