.page-header {
  border-bottom: 1px solid #eaecf0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--page-header-height);
  display: flex;
  align-items: center;
  padding: 0 var(--padding-container);
  justify-content: space-between;
  z-index: 1;
  background-color: #f9f9f9;
}
