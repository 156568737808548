.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  display: flex;
}

.modal__position--bottom {
  align-items: flex-end;
}

.modal__position--center {
  align-items: center;
  justify-content: center;
}

.modal__overflow--scroll {
  overflow: scroll;
}

.modal__overflow--hide {
  overflow: hidden;
}

.modal__backdrop {
  background: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
}

.modal__container {
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  max-height: calc(100vh - 64px);
  transition: all 330ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
  box-shadow: rgb(0 0 0 / 10%) 0px -4px 10px;
}

.modal__container--full-width {
  width: 100%;
}

.modal__container--full-height {
  height: 100%;
  max-height: 100vh;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header__close {
  cursor: pointer;
}

.modal-body {
  padding-top: 24px;
}

.modal-body:first-child {
  padding-top: 0;
}

.modal-body:not(:last-child) {
  padding-bottom: 24px;
}
