html,
body {
  font-family: "Maison Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

body > #root {
  background-color: #fff;
  width: inherit;
  height: 100vh;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

* {
  box-sizing: border-box;
}

:root {
  --width-app: 480px;
  --page-header-height: 54px;
  --padding-container: 20px;
  --text-color-brand: #0085ad;
  --interactive-fill-selected-default: #00aed6;
  --interactive-fill-brand-default: #00aed6;
  --interactive-fill-brand-hover: #0ed1ff;
  --interactive-fill-brand-active: #0085ad;
  --disabled: #9d9d9d;
  --warning: #ff991f;
  --background-color-disabled: #dcdcdc;
}

/* Google map auto suggestion css */
.pac-container:after {
  /* Hide 'powered by Google' */

  background-image: none !important;
  height: 0px;
}

.pac-icon.pac-icon-marker {
  /* Hide location marker */
  display: none;
}

.pac-item {
  margin: 0 10px;
}

a {
  text-decoration: none;
}
