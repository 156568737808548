.text {
  font-family: "Maison Neue";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.text--bold {
  font-weight: 600;
}

.text__fontSize--xs {
  font-size: 12px;
}

.text__fontSize--default {
  font-size: 16px;
}

.text__fontSize--l {
  font-size: 18px;
}

.text--align-center {
  text-align: center;
}

.text__fontSize--s {
  font-size: 14px;
}

.text__color--default {
  color: #243658;
}

.text__color--disabled {
  color: var(--disabled);
}

.text__color--brand {
  color: var(--text-color-brand);
}

.text--clickable {
  cursor: pointer;
}

.text__color--error {
  color: #de3509;
}
