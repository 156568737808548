.title {
  font-family: "Maison Neue";
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: #1b2842;
  margin: 0;
}

.title__font-size--m {
  font-size: 24px;
}

.title__font-size--l {
  font-size: 28px;
}
