.modal-language__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-container);
  border-bottom: 1px solid rgb(230, 233, 239);
}

.modal-language__option > label {
  cursor: pointer;
  width: 100%;
}

.modal-language__option > input {
  cursor: pointer;
}
